import Util from '../util/util.js';
export default class Slides {
  constructor(options) {
    this.$el = options.$el;
    this.slides = [];
    this.touchStart = 0;
    this.index = 0;
    this.delta = 0;
    this.event = null;
    this.pauseEvents = false;
    this.pause = false;
    this.pauseExternal = false;
    this.running = true;
    this.slidesDelay = 2;

    this.onChange = options.onChange || null;
    this.onEnd = options.onEnd || null;
    this.beforeChange = options.beforeChange || null;

    this.init();
    this.resize();
  }
  init() {
    this.slides = Array.from(this.$el.children).filter(s => s.tagName !== 'SVG');
    this.move = this.move.bind(this);
    this.touchstart = this.touchstart.bind(this);
    this.touchmove = this.touchmove.bind(this);
    this.onResize = this.onResize.bind(this);
    this.wheel = this.wheel.bind(this);

    window.addEventListener('wheel', this.move);
    document.addEventListener('touchmove', this.move);
    document.addEventListener('touchstart', this.touchstart);
    window.addEventListener('resize', this.onResize);

    this.updateStage(true)
  }
  onResize() {
    this.resize();
  }
  move(e) {
    this.event = e;
    if (!this.pauseEvents && !this.pause && !this.pauseExternal) {
      if(Util.isTouch()) {
        requestAnimationFrame(this.touchmove);
      }else {
        requestAnimationFrame(this.wheel);
      }
      this.pauseEvents = true;
    }
  }
  touchstart(e) {
    this.touchStart = e.touches[0].clientY;
  }
  wheel () {
    this.delta = this.event.wheelDeltaY || -1 * this.event.deltaY;
    if (this.delta < -0.4) {
      this.next(() => {
        if(this.index >= this.slides.length - 1 && this.delta < -0.4) {
          this.running = false;
          // window.scroll.pause = false;
        }else {
          this.running = true;
        }
      });
    }
    else if (this.delta > 0.4) {
      this.prev();
    }
    this.pauseEvents = false;
  }
  touchmove () {
    if(this.event.touches && this.event.touches[0]) {
      this.delta = this.event.touches[0].clientY - this.touchStart;
      if (this.delta < -0.4) {
        this.next(() => {
          if(this.index >= this.slides.length - 1 && this.delta < -0.4) {
            this.running = false;
          }else {
            this.running = true;
          }
        });
      }
      else if (this.delta > 0.4) {
        this.prev();
      }
    }
    this.pauseEvents = false;
  }
  next(cb) {
    if (this.index < this.slides.length - 1) {
      this.pause = true;
      this.index++;
      this.beforeChange(this.index, false);
      const current = this.slides[this.index];
      current.classList.add('active');

      let done = false;
      this.slides.forEach((slide, i) => {
        if(slide !== current) {
          slide.classList.remove('active');
        }
      });
      this.updateStage();
      setTimeout(() => {
        if(!done) {
          done = true;
          this.pause = false;
          if(cb) cb();
        }
      }, this.slidesDelay * 1000);
    }else {
      this.pause = true;
      console.log('end')
      this.onEnd();
    }
  }
  prev(cb) {
    if (this.index > 0) {
      this.pause = true;
      this.running = true;
      // window.scroll.pause = true;
      this.index--;
      this.beforeChange(this.index, true);
      const current = this.slides[this.index];
      current.classList.add('active');
      let done = false;
      this.slides.forEach((slide, i) => {
        if(slide !== current) {
          slide.classList.remove('active');
        }
      });
      this.updateStage(false, true);
      setTimeout(() => {
        if(!done) {
          done = true;
          this.pause = false;
          if(cb) cb();
        }
      }, this.slidesDelay * 1000);
    }
  }
  beforeChange(index, isPrev) {
    if(this.onBeforeChange) this.beforeChange(index, isPrev);
  }
  updateStage(isIntro, isPrev) {
    const current = this.slides[this.index];
    const type = current.getAttribute('data-type');
    this.$el.style.transform = `translateX(${-current.offsetLeft}px)`;
    if(isIntro) {
      current.classList.add('active');
    }
    if(this.onChange) this.onChange(this.index, current, isPrev);
  }
  resize() {
  }
}