import '../../styles/index.scss'
import '../../styles/pages/index.scss'
import gsap from 'gsap';

import Page from './page.js'
import Util from '../util/util.js';
import Slides from '../components/slides.js';


export default class Index extends Page {
  constructor(options) {
    super(options);
  }
  init() {
    super.init()
    this.onSlidesChange = this.onSlidesChange.bind(this);
    this.onBeforeChange = this.onBeforeChange.bind(this);
    this.$bg = document.getElementById('header-bg');
    this.$maskedContainer = document.querySelector('#header-masked-text-container');
    this.$mask = document.querySelector('#header-mask path');
    this.$coloredText = document.querySelector('#header-masked-text');
    this.$pattern = this.$maskedContainer.querySelector('[mask="url(#header-mask)"]');

    document.querySelectorAll('.header-slide').forEach(($slide, i) => {
      $slide.setAttribute('data-real-index', i);
    });
    this.textMaxX = (window.innerWidth * 1.2) / this.$maskedContainer.clientWidth;
    this.normalRedSlides = 3;
    this.emptySlidesCount = Math.ceil((this.$maskedContainer.clientWidth * 0.9)/window.innerWidth) - this.normalRedSlides;
    this.touchM = 0.000038;
    if(window.innerWidth <= 580) {
      this.emptySlidesCount = 4;
      //this.emptySlidesCount *= this.touchM * 20000;
      this.emptySlidesCount = Math.floor(this.emptySlidesCount);
    }
    if(this.emptySlidesCount < 2) {
      this.emptySlidesCount = 2;
    }
    this.emptySlidesCount = 1;
    this.finalRedSlides = this.emptySlidesCount + this.normalRedSlides;

    const emptySlidesPerSide = Math.floor(this.emptySlidesCount/2);
    for (let i = 1; i < this.normalRedSlides; i++) {
      for (let j = 0; j < emptySlidesPerSide; j++) {
        const $slide = document.createElement('div');
        $slide.classList.add('header-slide');
        $slide.classList.add('header-slide-empty');
        $slide.setAttribute('data-bg-color', '#C41D32');

        const index = i;
        document.querySelector('#header-slides').insertBefore($slide, document.querySelector(`.header-slide[data-real-index="${index}"]`));
      }
    }
    document.querySelectorAll('#header-slides .h1, .h2, .h3:not(.title-with-background), #header-slides .header-slide-content > p').forEach(($el) => {
      $el.classList.add('smart-text');
    });
    this.text = {
      x: {c: 0, t: 0, m: 0.09},
      y: {c: 0, t: 0, m: 0.05}
    };
    this.slides = new Slides({
      $el: document.getElementById('header-slides'),
      onChange: this.onSlidesChange,
      onEnd: () => {
        location.href = './home.html';
      },
      beforeChange: this.onBeforeChange,
    });
    this.isAuto = true;
    this.slides.pauseExternal = true;
    const textRaf = () => {
      this.text.x.c += (this.text.x.t - this.text.x.c) * this.text.x.m;
      this.text.y.c += (this.text.y.t - this.text.y.c) * this.text.y.m;

      this.$maskedContainer.style.transform = `translateX(${-this.text.x.c * 110}%) translateY(${this.text.y.c}px)`;
      requestAnimationFrame(textRaf);
    };
    textRaf();
    this.onWheel = this.onWheel.bind(this);
    this.touchstart = this.touchstart.bind(this);
    this.touchmove = this.touchmove.bind(this);
    //window.addEventListener('wheel', this.onWheel);
    //document.addEventListener('touchmove', this.touchmove);
    //document.addEventListener('touchstart', this.touchstart);
  }
  touchstart(e) {
    this.touchStart = e.touches[0].clientY;
  }
  touchmove(e) {
    if(this.isAuto) return;
    let delta = this.touchStart - e.touches[0].clientY;
    const direction = delta < 0 ? 'up' : 'down';
    delta *= this.touchM;
    const t = Math.max(Math.min(this.text.x.t + delta, 1 - this.textMaxX), 0);
    if(!(delta < 0 && this.text.x.t === (1 - this.textMaxX) && this.slides.index > this.finalRedSlides - 1)) {
      this.text.x.t = t;
    }
    if(this.text.x.t < (1 - this.textMaxX) && this.slides.index >= this.finalRedSlides - 1 && direction === 'down') {
      this.slides.pauseExternal = true;
    } else if(direction === 'up' || (this.text.x.t === (1 - this.textMaxX) && this.slides.index >= this.finalRedSlides - 1 && direction === 'down')) {
      this.slides.pauseExternal = false;
    }
  }
  onWheel(e) {
    if(this.isAuto) return;
    let delta = e.wheelDeltaY || -1 * e.deltaY;
    const direction = delta < 0 ? 'down' : 'up';
    delta *= -0.0001;
    delta = Math.min(Math.max(delta, -0.005), 0.005);
    const t = Math.max(Math.min(this.text.x.t + delta, 1 - this.textMaxX), 0);
    if(!(delta < 0 && this.text.x.t === (1 - this.textMaxX) && this.slides.index > this.finalRedSlides - 1)) {
      this.text.x.t = t;
    }
    if(this.text.x.t < (1 - this.textMaxX) && this.slides.index >= this.finalRedSlides - 1 && direction === 'down') {
      this.slides.pauseExternal = true;
    } else if(direction === 'up' || (this.text.x.t === (1 - this.textMaxX) && this.slides.index >= this.finalRedSlides - 1 && direction === 'down')) {
      this.slides.pauseExternal = false;
    }
  }
  onSlidesChange(index, currentSlide, isPrev) {
    let bg = currentSlide.getAttribute('data-bg-color') || '#C41D32';
    this.$bg.style.backgroundColor = bg;
    if(window.innerWidth <= 580) {
      this.text.y.t = index === 0 ? window.innerHeight - this.$maskedContainer.clientHeight - 80 : 120;
    }
    
    document.getElementById('header').setAttribute('data-slide-index', index);
    document.getElementById('header').setAttribute('data-slide-real-index', currentSlide.getAttribute('data-real-index'));
    document.querySelector('.header-footer-scroll').classList.toggle('hide', !(index === 0 || index >= this.finalRedSlides - 1));
  }
  onBeforeChange(index, isPrev) {
    if(index === this.finalRedSlides - 2 && isPrev) {
      this.toStart();
    }
  }
  paralax(data) {
    super.paralax(data);
  }
  initIntro() {
    super.initIntro();
    const $first = document.querySelector('.header-slide');
    gsap.set($first.querySelector('.title-with-background-bg'), {scaleY: 0});
    gsap.set($first.querySelector('.title-with-background span'), {opacity: 0, y: 200});

    gsap.set($first.querySelectorAll('.header-slide-content .smart-text .line .text'), {opacity: 0, y: 200, scaleY: 2});
    gsap.set($first.querySelector('.header-slide-image'), {opacity: 0});
    gsap.set(document.querySelector('.header-footer'), {opacity: 0});
    this.text.x = {c: 1, t: 1, m: 0.04};
  }
  intro() {
    super.intro();
    const $first = document.querySelector('.header-slide');
    gsap.to($first.querySelector('.title-with-background-bg'), {duration: 2, scaleY: 1, ease: 'power4.out'});
    gsap.to($first.querySelector('.title-with-background span'), {duration: 2, opacity: 1, y: 0, delay: 0.2, ease: 'power4.out'});

    gsap.to($first.querySelectorAll('.header-slide-content .smart-text .line .text'), {duration: 2, opacity: 1, y: 0, scaleY: 1, stagger: 0.1, delay: 0.1, ease: 'power4.out'});
    gsap.to($first.querySelector('.header-slide-image'), {duration: 2, opacity: 1, delay: 1.5, ease: 'power4.out'});
    gsap.to(document.querySelector('.header-footer'), {duration: 2, opacity: 1, delay: 1.5, ease: 'power4.out', onComplete: () => {
      this.text.x.m = 0.09;
      $first.classList.add('intro-done');
      window.addEventListener('wheel', () => {
        if(!this.started)
          this.startAuto();
      });
      document.addEventListener('touchmove', () => {
        if(!this.started)
          this.startAuto();
      });
    }});
    this.text.x.t = 0;
  }
  startAuto() {
    this.isAuto = true;
    this.started = true;
    this.pause = false;
    this.text.x.m = 0.02;
    const isMobile = window.innerWidth <= 580;
    const time = isMobile ? 3500 : 2500;
    const go = () => {
      if(this.isAuto && this.slides.index < this.finalRedSlides - 1) {
        this.slides.next();
      }else {
        clearInterval(interval);
        this.isAuto = false;
        this.slides.pauseExternal = false;
      }
    }
    const interval = setInterval(go, time);
    const m = isMobile ? 0.006 : 0.005;
    const raf = () => {
      if(this.isAuto) {
        this.text.x.t += (1 - this.text.x.t) * m;
      }
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
    go();
  }
  toStart() {
    this.slides.pauseExternal = true;
    this.slides.index = 0;
    this.text.x.t = 0;
    this.text.x.c = 0;
    this.text.x.m = 0.02;
    setTimeout(() => {
      this.startAuto();
    }, 2000);
  }
  resize() {
    super.resize();
    this.isMobile = window.innerWidth <= 580;
    const firstContent = document.querySelector('.header-slide-content');
    if(firstContent) {
      let offset = firstContent.offsetTop + firstContent.clientHeight;
      if(this.isMobile) {
        offset = 0;
      }
      this.$maskedContainer.style.top = `${offset}px`;
    }
  }
}
new Index({name: 'index'});